import React from 'react';
import styles from './Form.module.scss';
import commonStyles from './Common.module.scss'

function Privacy() {
  return (
    <div className={styles.section}>
      <div className={`${styles.content} ${commonStyles.privacy}`}>
        <h1>STARNET INSURANCE COMPANY - PRIVACY NOTICE</h1>
        <p>StarNet Insurance Company (the "Company"), a member company of the W. R. Berkley Corporation ("Berkley") group of companies and each other member of the Berkley group of companies ("Affiliates") understands our customers’ concern about privacy of their information collected by the Company. Our Company is dedicated to protecting the confidentiality and security of nonpublic personal information we collect about our customers in accordance with applicable laws and regulations. This notice refers to the Company by using the terms “us,” “we,” or “our.” The law requires that we send you a notice describing our privacy policy and how we treat the nonpublic personal information about our customers that we receive in connection with our business ("Information").</p>

        <h2>Why We Collect and How We Use Information:</h2>
        <p>We collect and use Information for business purposes with respect to our insurance products and services and other business relations involving our customers. We gather this Information to evaluate your request for insurance, to evaluate your insurance claims, to administer, maintain or review your insurance policy, and to process your insurance transactions. We also accumulate certain information about you as may be required or permitted by law.</p>
        <p>Your insurance agent or broker also collects this Information and may use it to help with your overall insurance program or to market additional products and services to you. We may also use Information to offer you other products or services that we or our Affiliates provide.</p>

        <h2>How We Collect Information:</h2>
        <p>Most Information collected by us is provided by you or your insurance agent or broker to us. We obtain Information from (i) applications or other forms submitted by you, your insurance agent or broker or your authorized representatives to us and our Affiliates, and (ii) your transactions with us or our Affiliates. We may also obtain Information from other sources such as (i) consumer reporting agencies, (ii) other institutions or information services providers, (iii) employers, (iv) other insurers, or (v) your family members.</p>

        <h2>Information We Disclose:</h2>
        <p>We disclose any Information which we believe is necessary to conduct our business as permitted by applicable law or where required by applicable law. This disclosure may include (i) Information we receive from you on applications or other forms provided to us and our Affiliates, such as names, addresses, social security numbers, assets, employer information, salaries, etc. (ii) Information about your transactions with us and our Affiliates, such as policy coverages, premiums, payment history, etc., and (iii) Information we receive from a consumer reporting agency, such as credit worthiness and credit history.</p>

        <h2>To Whom We Disclose Information:</h2>
        <p>We may, as permitted or required by applicable law, disclose your Information to nonaffiliated third parties, such as (i) your insurance agent or broker, (ii) independent claims adjusters, (iii) insurance support organizations, (iv) processing companies , (v) actuarial organizations, (vi) law firms, (vii) other insurance companies involved in an insurance transaction with you, (viii) law enforcement, regulatory, or governmental agencies, (ix) courts or parties therein pursuant to a subpoena or court order, (x) businesses with whom we have a marketing agreement, or (xi) our Affiliates.</p>
        <p>We may share Information with our Affiliates so that they may offer you products and services from the Berkley group of companies or to analyze our book of business and to consolidate necessary information. We do not disclose Information to other companies or organizations not affiliated with us for the purpose of using Information to sell their products or services to you. For example, we do not sell your name to unaffiliated mail order or direct marketing companies.</p>

        <h2>How We Protect Information:</h2>
        <p>We require our employees to protect the confidentiality of Information as required by applicable law. Access to Information by our employees is limited to administering, offering, servicing, processing or maintaining of our products and services. We also maintain physical, electronic and procedural safeguards designed to protect Information. When we share or provide Information to other persons or organizations, we contractually obligate them, if required by law, to treat Information as confidential and conform to our privacy policy and applicable laws and regulations.</p>

        <h2>Correction and Access to Information:</h2>
        <p>Upon our receipt of your written request to us at StarNet Insurance Company, 475 Steamboat Road, Greenwich, Connecticut 06830 we will generally make Information available for your review. If you believe the Information we have about you is incorrect or inaccurate, you may request that we make any necessary corrections, additions or deletions. If we agree with your belief, we will correct our records if required by applicable law. If we do not agree, you may submit to us a short statement of dispute, which we will include in any future disclosure by us of such Information if required by applicable law.</p>

        <h2>Requirements for Privacy Notice:</h2>
        <p>This privacy notice is being provided due to recently enacted federal and state laws and regulations establishing new privacy standards and requires us to provide this privacy policy. For additional information regarding our privacy policy, please write to us at 475 Steamboat Road, Greenwich, Connecticut 06830</p>

        <p>Dated: May 22, 2001 (Company zip code revised 08 Jan 2004)</p>
      </div>
    </div>
  )
}

export default Privacy;
