import {useNavigate, useLocation} from "react-router-dom";
import { Logo } from './Common'
import styles from './Header.module.scss'
import commonStyles from './Common.module.scss'

export function Header() {

  const location = useLocation();
  const isHomePage = (location.pathname === '/');
  const navigate = useNavigate();
  return (
    <div className={`${styles.header} ${isHomePage ? styles.headerHome : ''}`}>
      <div className={styles.headerContent}>
        <Logo/>
        <button className={`${isHomePage ? commonStyles.buttonHeaderHome : commonStyles.buttonClear}`} onClick={(e) => navigate("/contact")}>Contact Us</button>
      </div>
    </div>
  );
}
