
// notice we are destructuring the props here. You could change this to
// bring in props instead and then just add `props.` to animal, onEditClick and onDeleteClick:
// export default function AnimalItem(props)

import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import styles from './Form.module.scss';
import useState from 'react-usestateref'
import AnimalForm from "./AnimalForm";

export default function AnimalItem({
  animal,
  formProps,
  index,
  onDelete,
  approvalCode
}) {


  const [itemState, setItemState, itemStateRef] = useState(animal.itemState ? animal.itemState:"CLOSED");
  const { trigger, setValue, formState: { errors }} = formProps;


  async function onEditClick() {
    // animal.itemState = "EDIT";
    await trigger();
    if (errors.animals){
      console.log("Not Opening in Edit form as there are errors in opened forms");
    } else {
      setItemState("EDIT");
      setValue(`animals[${index}].itemState`,"EDIT");
    }
  }

  function onDeleteClick() {
    onDelete(animal,index);
  }

  return (
    <>
      {
        ["FIRST","NEW","EDIT"].includes(itemStateRef.current) &&
        <AnimalForm
          animal={animal}
          formProps={formProps}
          index={index} 
          setItemState = {setItemState}
          itemStateRef = {itemStateRef}
          onDeleteClick = {onDeleteClick}
          approvalCode =  {approvalCode}/>
      }
      {
        ["CLOSED"].includes(itemStateRef.current) &&
        <div className={styles.animalRollup}>
          <div>
            <label>Lot Number</label>
            <div className={styles.rollupValue}># {animal.lotno}</div>
          </div>
          <div>
            <label>Purchase Price</label>
            <div className={styles.rollupValue}>$ {animal.purchaseprice}</div>
          </div>
          <div className={styles.actionBtns}>
            <button className={styles.editButton} onClick={onEditClick}><FontAwesomeIcon icon={faEdit}/>Edit</button>
            <button type="button" className={styles.editButton} onClick={onDeleteClick}><FontAwesomeIcon icon={faTrash}/>Delete</button>
          </div>
        </div>
      }

    </>);

}
