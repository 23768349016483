
import AnimalItem from "./AnimalItem";

export default function AnimalsComponent({
    formProps,
    fieldArrayProps,
    approvalCodeRef,
  }) {

    const { register, trigger, handleSubmit,setError, control, watch, setValue, getValues, formState: { isSubmitting,errors }} = formProps;
    const { fields, append, remove } = fieldArrayProps;

      function handleAnimalDelete(animal,index) {
        remove(index);
      }

    return (
        <>
            {fields.map((field, index) => (
                <AnimalItem
                    key={field.id}
                    formProps={formProps}
                    index={index}
                    animal={field}
                    approvalCode={approvalCodeRef.current}
                    onDelete={handleAnimalDelete}
                />
            ))}
        </>
    );

  }
