import React, {useState, useEffect, useMemo} from "react";
import styles from './Form.module.scss';
import moment from 'moment';

export default function CustomDatePicker({
    value,
    onChange
}) {

  const [date, setDate] = useState(value ? new Date(value) : new Date());

  // Generate dynamic days based on the month and year
  const getDaysInMonth = (year, month) => {
    return new Array(new Date(year, month, 0).getDate()).fill(null).map((_, index) => (index + 1).toString());
  };

  const days = useMemo(() => getDaysInMonth(date.getFullYear(), date.getMonth() + 1), [date]);
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const years = useMemo(() => {
    const currentYear = new Date().getFullYear();
    return new Array(6).fill(null).map((_, index) => (currentYear - index).toString());
  }, []);

  useEffect(() => {
    if (value) {
      const newDate = new Date(value);
      setDate(new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate()));
    }
  }, [value]);

  const handleDateChange = (newDateValue) => {
    // Validate the new date before setting it
    if (moment(newDateValue, 'MM/DD/YYYY', true).isValid()) {
      setDate(newDateValue);
      onChange(newDateValue);
    }
  };

  return (
    <div className={styles.customDatePicker}>
      <select value={date.getMonth() + 1} onChange={e => handleDateChange(new Date(date.getFullYear(), e.target.value - 1, date.getDate()))}>
        {months.map((monthName, index) => (
          <option key={monthName} value={index + 1}>
            {monthName}
          </option>
        ))}
      </select>
      <select value={date.getDate()} onChange={e => handleDateChange(new Date(date.getFullYear(), date.getMonth(), e.target.value))}>
        {days.map(day => (
          <option key={day} value={day}>
            {day}
          </option>
        ))}
      </select>
      <select value={date.getFullYear()} onChange={e => handleDateChange(new Date(e.target.value, date.getMonth(), date.getDate()))}>
        {years.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );
}
