import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form'
import styles from './Home.module.scss';
import commonStyles from './Common.module.scss'
import {useNavigate} from "react-router-dom";
import moment from 'moment';

function Home() {

  useEffect(() => {
    window.sessionStorage.clear();
    window.localStorage.clear();
  });

  return (
    <>
      <HeroSection/>
      <Requirements/>
      <WhyUs/>
    </>
  )
}


function HeroSection() {
  const { register, handleSubmit, control, watch, setValue, formState: { errors, isValid }} = useForm();
  const [auctionDate, onAuctionDateChange] = useState('');
  const navigate = useNavigate();

  const onSubmit = (evt) => {
    window.sessionStorage.setItem("auctionDate", auctionDate);
    navigate("/form1");
  }

  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  return (
    <div className={styles.hero}>
      <div className={styles.bgImg} style={{
        backgroundImage: "url(/img/landing.jpg)",
      }}/>
      <div className={styles.heroContent}>
        <h1>Your insurance source for Beef Breeding Stock</h1>
        <h2>Auction Business Only</h2>
        <h4>AuctionDate</h4>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.formRow}>
            <div>
              <select id="auctionDate" className={styles.auctionDate} {...register("auctionDate", {
                    required: true,
                    validate: (value) => (value !== null)
                  })} onChange={e => onAuctionDateChange(e.target.value)}>
                <option value=""/>
                <option value={moment(yesterday).format('MM/DD/yyyy')}>{moment(yesterday).format('MMMM D, YYYY')}</option>
                <option value={moment(today).format('MM/DD/yyyy')}>{moment(today).format('MMMM D, YYYY')}</option>
              </select>
              <div className={styles.auctionDateError}>
                    {errors.auctionDate && "Auction date is required"}
              </div>
            </div>
            <input type="submit" value="Get Started" className={commonStyles.buttonGetStarted}/>
          </div>
        </form>

      </div>
    </div>
  );
}

function Requirements() {
  return (
    <div className={styles.requirements}>
      <div className={styles.requirementsContent}>
        <h1>Requirements</h1>
        <div className={styles.requirementsList}>
          <div className={styles.requirementItem}>
            <div className={styles.icon}/>
            <span>All cattle must be insured within 24 hours of sale closing.***</span>
          </div>
          <div className={styles.requirementItem}>
            <div className={styles.icon}/>
            <span>Insured value cannot exceed the cash auction purchase price.</span>
          </div>
          <div className={styles.requirementItem}>
            <div className={styles.icon}/>
            <span>Cattle must be beef breeding stock (bulls, heifers and cows). For steers and horses contact <a className={commonStyles.externalLinkAlt} target={'blank'} href="https://insureyourlivestock.com/">insureyourlivestock.com</a></span>
          </div>
          <div className={styles.requirementItem}>
            <div className={styles.icon}/>
            <span>No vet statement is required if coverage is requested within 24 hours of sale closing.</span>
          </div>
          <div className={styles.requirementItem}>
            <div className={styles.icon}/>
            <span>Cows and bulls cannot be more than 5 years old and no less than 90 days of age.</span>
          </div>
          <div className={styles.requirementItem}>
            <div className={styles.icon}/>
            <span>Coverage is bound immediately.</span>
          </div>
          <div className={styles.requirementItem}>
            <div className={styles.icon}/>
            <span>Rate is 6.9% annual, covering general mortality and theft.</span>
          </div>
          <div className={styles.requirementItem}>
            <span className={styles.footnote}>*** Contact <a className={commonStyles.externalLinkAlt} target={'blank'} href="https://insureyourlivestock.com">insureyourlivestock.com</a> for all insurance requests more than 24 hours after sale closing.</span>
          </div>
        </div>
      </div>
    </div>
  );
}

function WhyUs() {
  return (
    <div className={styles.whyUs}>
      <h1>Why us?</h1>
      <div className={styles.items}>
        <div className={styles.item}>
          <div className={`${styles.icon} ${styles.sixPointNinePct}`}/>
          <p>6.9% Annual Rate</p>
        </div>
        <div className={styles.item}>
          <div className={`${styles.icon} ${styles.mortality}`}/>
          <p>Covers general mortality and theft.</p>
        </div>
        <div className={styles.item}>
          <div className={`${styles.icon} ${styles.immediate}`}/>
          <p>Coverage is bound immediately.</p>
        </div>
        <div className={styles.item}>
          <div className={`${styles.icon} ${styles.aplus}`}/>
          <p>Coverage is being placed with an A+ superior company.</p>
        </div>
      </div>
    </div>
  )
}

export default Home
