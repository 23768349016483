import React from 'react';
import styles from './Footer.module.scss'
import { Logo, BerkleyLogo } from './Common'
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer>
      <div className={styles.footerContent}>
        <div className={styles.footerTopRow}>
          <div className={styles.footerLogo}>
            <Logo/>
          </div>
          <div className={styles.footerSocial}>
            <BerkleyLogo/>
          </div>
          <div className={styles.footerSocial}>
            <a href="https://www.facebook.com/Insureyourcattlecom-106217768636961/"
               target="_blank" rel="noreferrer"><img className={styles.socialLink} src="img/facebook.svg" alt="Facebook"/></a>
          </div>
          <div className={styles.footerLink}><Link to="/terms">Terms & Conditions</Link></div>
          <div className={styles.footerLink}><Link to="/privacy">Privacy Policy</Link></div>
          <div><Link to="/contact">Contact Us</Link></div>
        </div>
        <div className={styles.footerLine}/>
        <div className={styles.footerBottomRow}>
          &copy; InsureYourCattle.com, Inc. 2024. All Rights Reserved
        </div>
      </div>
    </footer>
  )
}

export default Footer
