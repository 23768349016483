import React from 'react';
import {useNavigate} from "react-router-dom";

export function Logo() {
  const navigate = useNavigate();
  return <img src={`img/logo.svg`} alt="Insure Your Cattle" onClick={(e) => navigate("/")}/>
}

export function BerkleyLogo() {
  return Img("berkley_logo.svg", "Berkley Equine & Cattle, a Berkley company");
}

export function Img(filename, alt) {
  return <img src={`img/${filename}`} alt={alt}/>
}
