import React from 'react';
import styles from './Form.module.scss';
import commonStyles from './Common.module.scss'

function Terms() {
  return (
    <div className={styles.section}>
      <div className={`${styles.content} ${commonStyles.terms}`}>
        <h1>Terms and Conditions</h1>
        <p><span>STANDARD</span> &ndash; Any person who knowingly and with intent to defraud any insurance company or other person, files an application for insurance or statement of claim containing any materially false information or conceals, for the purpose of misleading, information concerning any fact material hereto, commits a fraudulent act, which is a crime, and may subject such persons to criminal and civil penalties.</p>
        <p><span>NOTICE TO ARKANSAS APPLICANTS</span> &ndash; Warning &ndash; Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.</p>
        <p><span>NOTICE TO COLORADO APPLICANTS</span> &ndash; It is unlawful to knowingly provide false, incomplete, or misleading facts or information to an insurance company for the purpose of defrauding or attempting to defraud the company. Penalties may include imprisonment, fines, denial of insurance, and civil damages. Any insurance company or agent of an insurance company who knowingly provides false, incomplete, or misleading facts or information to a policyholder or claimant for the purpose of defrauding or attempting to defraud the policyholder or claimant with regard to a settlement or award payable from insurance proceeds shall be reported to the Colorado Division of Insurance within the Department of Regulatory Agencies.</p>
        <p><span>NOTICE TO DISTRICT OF COLUMBIA APPLICANTS</span> &ndash; WARNING: It is a crime to provide false or misleading information to an insurer for the purpose of defrauding the insurer or any other person. Penalties include imprisonment and/or fines. In addition, an insurer may deny insurance benefits if false information materially related to a claim was provided by the applicant.</p>
        <p><span>NOTICE TO FLORIDA APPLICANTS</span> &ndash; Any person who knowingly and with intent to injure, defraud, or deceive any insurer files a statement of claim or an application containing any false, incomplete, or misleading information is guilty of a felony of the third degree.</p>
        <p><span>NOTICE TO KENTUCKY APPLICANTS</span> &ndash; Warning: Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance containing any materially false information or conceals, for the purpose of misleading, information concerning any fact material thereto commits a fraudulent insurance act, which is a crime.</p>
        <p><span>NOTICE TO LOUISIANA APPLICANTS</span> &ndash; Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.</p>
        <p><span>NOTICE TO MAINE APPLICANTS</span> &ndash; It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties may include imprisonment, fines or a denial of insurance benefits.</p>
        <p><span>NOTICE TO NEW YORK APPLICANTS</span> &ndash; Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance or statement of claim containing any materially false information, or conceals for the purpose of misleading, information concerning any fact material thereto, commits a fraudulent insurance act, which is a crime, and shall also be subject to a civil penalty not to exceed five thousand (5,000) dollars and the stated value of the claim for each such violation.</p>
        <p><span>NOTICE TO NEW JERSEY APPLICANTS</span> &ndash; Any person who includes any false or misleading information on an application for an insurance policy is subject to criminal and civil penalties.</p>
        <p><span>NOTICE TO NEW MEXICO APPLICANTS</span> &ndash; Any person who knowingly presents a false or fraudulent claim for payments of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to civil fines and criminal penalties.</p>
        <p><span>NOTICE TO OHIO APPLICANTS</span> &ndash; Any person who, with intent to defraud or knowing that he is facilitating a fraud against an insurer, submits an application or files a claim containing a false or deceptive statement is guilty of insurance fraud.</p>
        <p><span>NOTICE TO OKLAHOMA APPLICANTS &ndash; WARNING:</span> Any person who knowingly, and with intent to injure, defraud or deceive any insurer, makes any claim for the proceeds of an insurance policy containing any false, incomplete or misleading information is guilty of a felony.</p>
        <p><span>NOTICE TO PENNSYLVANIA APPLICANTS</span> &ndash; Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance or statement of claim containing any materially false information or conceals for the purpose of misleading, information concerning any fact material thereto commits a fraudulent insurance act, which is a crime and subjects such person to criminal and civil penalties.</p>
        <p><span>NOTICE TO TENNESSEE APPLICANTS</span> &ndash; It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.</p>
        <p><span>NOTICE TO VIRGINIA APPLICANTS</span> &ndash; It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.</p>
        <p><span>NOTICE TO WEST VIRGINIA APPLICANTS</span> &ndash; Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.</p>
      </div>
    </div>
  )
}

export default Terms;
