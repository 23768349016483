import axios from 'axios';
import appConfig from "../config.json";

export const contactUs = async (contactUsRequest) => {
  try {
    return await axios({
      method: 'post',
      url: `${appConfig.backendBaseUrl}/contactus`,
      data: contactUsRequest,
    });
  } catch (error) {
    await reportableEvent({
      method: 'contactUs',
      contactUsRequest,
      error
    })
    throw error?.response?.data;
  }
}

export const submitApplication = async (data) => {
  try {
    return await axios({
      method: 'post',
      url: `${appConfig.backendBaseUrl}/apply`,
      data,
    });
  } catch (error) {
    await reportableEvent({
      method: 'submitApplication',
      registerRequest: data,
      error
    })
    throw error?.response?.data;
  }
}

export const getApprovalCode = async () => {
  return "JJ2022OK";
}

export async function reportableEvent(event) {
  console.debug('reportableEvent', event);
  const payload = {
    meta: {
      event: "ui.event"
    },
    data: event
  }
  try {
    return await axios({
      method: 'post',
      url: `${appConfig.backendBaseUrl}/events`,
      data: payload,
    });
  } catch (error) {
    console.log(`Error in reportableEvent(${JSON.stringify(event)})`)
    console.error(error)
  }
}
