import React, {useState} from 'react';
import { useForm } from "react-hook-form";
import { contactUs } from '../utils/backendService'
import { useNavigate } from 'react-router-dom';
import styles from './Form.module.scss';
import {VALID_EMAIL_RE} from './Constants';
import commonStyles from "./Common.module.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSpinner} from '@fortawesome/free-solid-svg-icons';


const ContactUs = () => {
  const { register, handleSubmit, clearErrors, setError, formState: {isSubmitting, errors} } = useForm();
  const [submitted, updatedSubmitted] = useState(false);

  async function onSubmit(data) {
    try {
      const result = await contactUs(data);
      console.log(result);
      updatedSubmitted(true);
      clearErrors('serverside');
    } catch (error) {
      console.log(error);
      setError("serverside", { message: `${error}` });
    }
  }

  function goHome() {
    updatedSubmitted(false);
    navigate("/");
  }

  const navigate = useNavigate();

  if (submitted) {
    return <>
      <div className={styles.section}>
        <div className={styles.content} style={{minHeight:'500px'}}>
          <div className={styles.fieldRow}>
            <div className={styles.field}>
              <h4>Thank you for your submission. We will get back to you soon.</h4>
            </div>
          </div>

          <div className={styles.buttonRow}>
            <input className={commonStyles.buttonClear} type="button" value="Go Home" onClick={() => goHome()}/>
          </div>
        </div>
      </div>
    </>;
  }

  return (
    <div>
      <div className={styles.section}>
        <div className={`${styles.content} ${commonStyles.contact}`}>
          <h1>Send us a note or call us!</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset>
              <div className={styles.fieldRow}>
                <div className={styles.field}>
                  <div className={styles.label}>Full Name</div>
                  <input type="text" id="fullname" placeholder="Enter name" {...register("fullname",
                    {
                      required: true,
                      maxLength: 30
                    })}/>
                  {errors.fullname && (
                    <div className={styles.error}>Name is required</div>
                  )}
                </div>
              </div>
              <div className={styles.fieldRow}>
                <div className={styles.field}>
                  <div className={styles.label}>Email</div>
                  <input type="text" id="email" placeholder="example@address.com" {...register("email",
                    {
                      required: {value: true, message: "Email is required"},
                      pattern: {
                        value: VALID_EMAIL_RE,
                        message: "Invalid email"
                      }
                    })} />
                  {errors.email && (
                    <div className={styles.error}>{errors.email.message}</div>
                  )}
                </div>
              </div>
              <div className={styles.fieldRow}>
                <div className={styles.field}>
                  <div className={styles.label}>Message</div>
                  <textarea placeholder="Enter a message" rows="10" {...register("message", {required: true, maxLength: 2048})}/>
                  {errors.message && (
                    <div className={styles.error}>Message is required</div>
                  )}
                </div>
              </div>
              {errors.serverside &&
                <div className={styles.fieldRow}>
                  <div className={styles.field}>
                    {errors.serverside?.message}
                  </div>
                </div>
              }
            </fieldset>

            <div className={commonStyles.contactButtonRow}>
              <button className={commonStyles.buttonFilled} type="submit" disabled={isSubmitting}>  {isSubmitting && ( <FontAwesomeIcon icon={faSpinner} spin={true}/>)} Send </button>
              <input className={commonStyles.buttonClear} type="button" value="Cancel" disabled={isSubmitting} onClick={() => navigate("/")}/>
            </div>
          </form>

          <div className={commonStyles.callUs}>
            <h2>Call us with any questions</h2>
            <div className={commonStyles.callUsRow}>
              <div>Jimmy James</div>
              <div><a href="tel:580-481-0955">580-481-0955</a></div>
            </div>
            <div className={commonStyles.callUsRow}>
              <div>Jace James</div>
              <div><a href="tel:580-481-0927">580-481-0927</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs;
